import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf from 'components/related/Form'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-style: solid;
    border-width: 1px;
    min-height: 50px;
    padding: 10px;
    width: 100%;
  }

  & .form-duuf-textarea {
    height: 150px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    color: ${({ theme }) => theme.color.light};
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.light};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    & button {
      width: 100%;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const StyledForm = styled.div`
  background-color: ${({ theme }) => theme.color.greyBg};
`

const Content = styled(ParseContent)`
  & h2,
  & p {
    color: ${({ theme }) => theme.color.light};
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <div className="row py-lg-5">
        <div className="col-md-4 col-lg-6">
          <Content content={fields.description} />
          <div className="d-flex flex-column my-4">
            <a
              href={fields?.phone?.url}
              className="d-flex align-items-center text-primary mb-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="19"
                viewBox="0 0 11 19"
                fill="none"
              >
                <g clipPath="url(#clip0_615_799)">
                  <path
                    d="M9.58065 0H1.41935C0.620968 0 0 0.604545 0 1.38182V17.6182C0 18.3091 0.620968 19 1.41935 19H9.58065C10.379 19 11 18.3955 11 17.6182V16.15V1.46818C11 0.604545 10.379 0 9.58065 0ZM3.45968 0.863636H7.54032C7.62903 0.863636 7.71774 1.03636 7.71774 1.20909C7.71774 1.38182 7.62903 1.55455 7.54032 1.55455H3.45968C3.37097 1.55455 3.28226 1.38182 3.28226 1.20909C3.28226 0.95 3.37097 0.863636 3.45968 0.863636ZM5.5 17.6182C4.96774 17.6182 4.6129 17.1864 4.6129 16.7545C4.6129 16.2364 5.05645 15.8909 5.5 15.8909C6.03226 15.8909 6.3871 16.3227 6.3871 16.7545C6.3871 17.1864 6.03226 17.6182 5.5 17.6182ZM9.93548 14.5955H1.06452V2.33182H9.93548V14.5955Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_615_799">
                    <rect width="11" height="19" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="ms-2 ps-1 text-primary">
                {fields?.phone?.title}
              </span>
            </a>
            <a href={`mailto:${fields?.email}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
              >
                <g clipPath="url(#clip0_615_803)">
                  <path
                    d="M14.0466 0H1.95758C0.877393 0 0 0.864718 0 1.9293V10.0748C0 11.1394 0.877393 12.0041 1.95758 12.0041H14.0466C15.1267 12.0041 16.0041 11.1394 16.0041 10.0748V1.93338C16.0041 0.868797 15.1267 0 14.0466 0ZM15.1102 10.0748C15.1102 10.654 14.6342 11.123 14.0466 11.123H1.95758C1.36989 11.123 0.893947 10.654 0.893947 10.0748V1.93338C0.893947 1.35418 1.36989 0.885112 1.95758 0.885112H14.0466C14.6342 0.885112 15.1102 1.35418 15.1102 1.93338V10.0748Z"
                    fill="white"
                  />
                  <path
                    d="M10.09 5.89782L14.0052 2.43895C14.1873 2.2758 14.2038 1.99844 14.0383 1.81489C13.8727 1.63542 13.5913 1.6191 13.4051 1.78226L8.00827 6.55452L6.95705 5.62862C6.95292 5.62454 6.94878 5.62046 6.94878 5.62046C6.92394 5.59599 6.90325 5.57967 6.87428 5.55928L2.59078 1.78226C2.40455 1.6191 2.12312 1.63542 1.95757 1.81897C1.79203 2.00252 1.80858 2.27988 1.99482 2.44303L5.9555 5.93046L2.01137 9.5688C1.83341 9.73603 1.821 10.0134 1.99068 10.1929C2.08173 10.2826 2.19761 10.3315 2.31763 10.3315C2.42524 10.3315 2.53698 10.2908 2.62389 10.2133L6.62596 6.52189L7.71029 7.47634C7.7972 7.54976 7.90067 7.58647 8.00827 7.58647C8.11587 7.58647 8.22348 7.54568 8.30625 7.47226L9.42369 6.48518L13.4051 10.2133C13.492 10.2948 13.6037 10.3356 13.7113 10.3356C13.8313 10.3356 13.9472 10.2908 14.0341 10.1969C14.2038 10.0215 14.1955 9.74011 14.0176 9.57288L10.09 5.89782Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_615_803">
                    <rect width="16" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="ms-2 text-primary">{fields?.email}</span>
            </a>
          </div>
          <div className="flex">
            <a
              href="https://www.instagram.com/vttuinen.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
              >
                <g clipPath="url(#clip0_634_66)">
                  <path
                    d="M13.75 0H6.25C2.79785 0 0 2.65796 0 5.9375V13.0625C0 16.342 2.79785 19 6.25 19H13.75C17.2021 19 20 16.342 20 13.0625V5.9375C20 2.65796 17.2021 0 13.75 0ZM18.125 13.0625C18.125 15.354 16.1621 17.2188 13.75 17.2188H6.25C3.83789 17.2188 1.875 15.354 1.875 13.0625V5.9375C1.875 3.646 3.83789 1.78125 6.25 1.78125H13.75C16.1621 1.78125 18.125 3.646 18.125 5.9375V13.0625Z"
                    fill="white"
                  />
                  <path
                    d="M10.0001 4.75C7.23639 4.75 5.00006 6.87915 5.00006 9.5C5.00006 12.1208 7.24127 14.25 10.0001 14.25C12.7588 14.25 15.0001 12.1255 15.0001 9.5C15.0001 6.87451 12.7637 4.75 10.0001 4.75ZM10.0001 12.4687C8.27643 12.4687 6.87506 11.1375 6.87506 9.5C6.87506 7.86255 8.27643 6.53125 10.0001 6.53125C11.7237 6.53125 13.1251 7.86255 13.1251 9.5C13.1251 11.1375 11.7237 12.4687 10.0001 12.4687Z"
                    fill="white"
                  />
                  <path
                    d="M16.04 4.39282C16.04 4.74072 15.7422 5.02368 15.376 5.02368C15.0098 5.02368 14.7119 4.74072 14.7119 4.39282C14.7119 4.04492 15.0098 3.76196 15.376 3.76196C15.7422 3.76196 16.04 4.04492 16.04 4.39282Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_634_66">
                    <rect width="20" height="19" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/Vttuinen"
              target="_blank"
              rel="noopener noreferrer"
              className="ms-3"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
              >
                <g clipPath="url(#clip0_634_71)">
                  <path
                    d="M6.25 6.53125V4.15625C6.25 3.5022 6.81152 2.96875 7.5 2.96875H8.75V0H6.25C4.17969 0 2.5 1.5957 2.5 3.5625V6.53125H0V9.5H2.5V19H6.25V9.5H8.75L10 6.53125H6.25Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_634_71">
                    <rect width="10" height="19" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div className="col-md-8 col-lg-6 mt-5 mt-md-0">
          <StyledForm className="px-4 px-lg-5 pt-5 pb-3">
            <StyledFormDuuf id={1} generate />
          </StyledForm>
        </div>
      </div>
    </div>
  </section>
)

export default FormContact
